<template>
  <div class="wrap">
    <div id="do_card_wrap"
         class="left_img"
         @mousedown="draggableFun"
         @mousemove="mousemove"
         @mouseup="up"
         :style="{
          width:wrapWidth + 'px',
          height:wrapHieght + 'px',
          'background-image':`url(${url})`,
          'flex-shrink':0
         }">
      <div class="line"
           v-for="(item,index) in lists"
           :key="index"
           :style="{
        top:item.points.y+'px',
        left:item.points.x+'px',
        width:item.points.w+'px',
        height:item.points.h+'px',
        background:item.color,
      }">

        <span v-if="item.points.w&& item.type!='lp'&& item.type!='lp1'&& item.type!='lp2'&& item.type!='lp3'&& item.type!='lp4'&& item.type!='lp5'&& item.type!='lp6'&& item.type!='lp7'&& item.type!='lp8'">
          {{item.label}}
        </span>
      </div>
    </div>
    <div class="
              right_wrap">
      <div class="list">
        <!-- 定位点区域 -->
        <div class="box">
          <div class="area_title">定位点区域</div>
          <div class="tip">说明：<br />
            <ol style="list-style-type: decimal;list-style-position: inside;">
              <li v-show="size===0||pointList.length==8">定位点1，定位点4，定位点5，定位点8为必须标注区域，分别为试卷左上角，右上角，右下角，左下角上的黑色小方块（顺时针方向）;</li>
              <li v-show="size===0">A3必须标注1,4,5,8这4个点，并尽量标注满8个定位点;</li>
              <li v-show="size===1&&pointList.length==4">定位点1，定位点2，定位点3，定位点4为必须标注区域，分别为试卷左上角，右上角，右下角，左下角上的黑色小方块（顺时针方向）;</li>

              <li v-show="size===1&&pointList.length==4">A4必须标注1,2,3,4这4个点;</li>
              <li>为了更好地识别，标注区域应覆盖试卷上的定位小黑块，并尽可能与试卷上的定位小黑块重合。</li>
            </ol>

          </div>
          <div v-for="(item,index) in pointList"
               :key="index"
               @click="checkItem(index)"
               :style="{color:index == activeIndex?'#409EFF':''}"
               class="item">
            <span>
              <span v-if="item.isRequire"
                    style="color:#f00;margin-left:-20px">*</span>
              <span style="width:100px;display:inline-block">{{item.name}}</span>
              <el-input v-model="item.label"
                        readonly
                        style="width:100px" />
            </span>
            <span>x:{{(item.points.x).toFixed(2)}}</span>
            <span>y:{{(item.points.y).toFixed(2)}}</span>
            <span>w:{{(item.points.w).toFixed(2)}}</span>
            <span>h:{{(item.points.h).toFixed(2)}}</span>
          </div>
        </div>

        <!-- 学生信息区域 -->
        <div class="box"
             v-if="this.num==1">
          <div class="area_title">学生信息区域<span>（3个区域必选一个）</span></div>
          <div v-for="(item,index) in infoList"
               :key="index+8"
               @click="checkItem((index+beforeInfoLen))"
               :style="{color:index+beforeInfoLen == activeIndex?'#409EFF':''}"
               class="item">
            <span>
              <span v-if="item.isRequire"
                    style="color:#f00;margin-left:-20px">*</span>
              <span style="width:100px;display:inline-block">{{item.name}}</span>
              <el-input v-model="item.label"
                        style="width:100px"
                        readonly />
            </span>
            <span>x:{{(item.points.x).toFixed(2)}}</span>
            <span>y:{{(item.points.y).toFixed(2)}}</span>
            <span>w:{{(item.points.w).toFixed(2)}}</span>
            <span>h:{{(item.points.h).toFixed(2)}}</span>
          </div>
        </div>

        <!-- 选择题区域 -->
        <div class="box">
          <div class="area_title">选择题区域</div>
          <div v-for="(item,index) in cqList"
               :key="index+beforeCqLen"
               @click="checkItem(index+beforeCqLen)"
               :style="{color:index+beforeCqLen== activeIndex?'#409EFF':''}"
               class="item">
            <span>
              <span v-if="item.isRequire"
                    style="color:#f00;margin-left:-20px">*</span>
              <span style="width:100px;display:inline-block">选择题区域{{index+1}}</span>
              <el-input v-model="item.label"
                        placeholder="请输入"
                        style="width:100px"
                        readonly />
            </span>
            <span>x:{{(item.points.x).toFixed(2)}}</span>
            <span>y:{{(item.points.y).toFixed(2)}}</span>
            <span>w:{{(item.points.w).toFixed(2)}}</span>
            <span>h:{{(item.points.h).toFixed(2)}}</span>
            <i style="color:red"
               @click="del(index,index+beforeCqLen,'cqList')"
               class="el-icon-delete"></i>
          </div>
          <el-button type="primary"
                     plain
                     @click="add('cq','cqList')"
                     class="btn">
            新增选择题区域
          </el-button>
        </div>

        <!-- 填空题区域 -->
        <!-- <div class="box">
          <div class="area_title">填空题区域</div>
          <div v-for="(item,index) in gqList"
               :key="index+beforeGqLen"
               @click="checkItem(index+beforeGqLen)"
               :style="{color:index+beforeGqLen== activeIndex?'#409EFF':''}"
               class="item">
            <span>
              <span v-if="item.isRequire"
                    style="color:#f00;margin-left:-20px">*</span>
              <span style="width:100px;display:inline-block">填空题区域{{index+1}}</span>
              <el-input v-model="item.label"
                        style="width:100px" />
            </span>
            <span>x:{{(item.points.x).toFixed(2)}}</span>
            <span>y:{{(item.points.y).toFixed(2)}}</span>
            <span>w:{{(item.points.w).toFixed(2)}}</span>
            <span>h:{{(item.points.h).toFixed(2)}}</span>
            <i style="color:red"
               @click="del(index,index+beforeGqLen,'gqList')"
               class="el-icon-delete"></i>
          </div>
          <el-button type="primary"
                     plain
                     @click="add('gq','gqList')"
                     class="btn">
            新增填空区域
          </el-button>
        </div> -->

        <!-- 主观题区域 -->
        <div class="box">
          <div class="area_title">主观题区域<span>（输入框需输入题号,多道题用【,】隔开）</span></div>
          <div v-for="(item,index) in sqList"
               :key="index+beforeSqLen"
               @click="checkItem(index+beforeSqLen)"
               :style="{color:index+beforeSqLen == activeIndex?'#409EFF':''}"
               class="item">
            <span>
              <span v-if="item.isRequire"
                    style="color:#f00;margin-left:-20px">*</span>
              <span style="width:100px;display:inline-block">主观题{{index+1}}</span>
              <el-input v-model="item.label"
                        style="width:150px"
                        placeholder="题号（例：1,2,3）" />
              <!-- <el-input-number v-model="item.label"
                               placeholder="请输入"
                               :min='0'
                               controls-position="right"
                               style="width:100px"
                               v-if="!item.isMultiple" /> -->

            </span>
            <span>x:{{(item.points.x).toFixed(2)}}</span>
            <span>y:{{(item.points.y).toFixed(2)}}</span>
            <span>w:{{(item.points.w).toFixed(2)}}</span>
            <span>h:{{(item.points.h).toFixed(2)}}</span>
            <i style="color:red"
               @click="del(index,index+beforeSqLen,'sqList')"
               class="el-icon-delete"></i>
          </div>
          <!-- <el-button type="primary"
                     plain
                     @click="add('sq','sqList')"
                     class="btn">
            新增主观题
          </el-button> -->
          <el-button type="primary"
                     plain
                     @click="add('sq','sqList',1)"
                     class="btn">
            新增主观题
          </el-button>
        </div>
        <div>

          <!-- 语文作文区域 -->
          <!-- <div class="box">
            <div class="area_title">语文作文区域<span>(语文试卷创建)</span></div>
            <div v-for="(item,index) in zwChineseList"
                 :key="index+beforeZwChineseLen"
                 @click="checkItem(index+beforeZwChineseLen)"
                 :style="{color:index+beforeZwChineseLen == activeIndex?'#409EFF':''}"
                 class="item">
              <span>
                <span v-if="item.isRequire"
                      style="color:#f00;margin-left:-20px">*</span>
                <span style="width:100px;display:inline-block">语文作文{{index+1}}</span>
                <el-input-number v-model="item.label"
                                 placeholder="请输入"
                                 :min='0'
                                 style="width:100px"
                                 controls-position="right" />
              </span>
              <span>x:{{(item.points.x).toFixed(2)}}</span>
              <span>y:{{(item.points.y).toFixed(2)}}</span>
              <span>w:{{(item.points.w).toFixed(2)}}</span>
              <span>h:{{(item.points.h).toFixed(2)}}</span>
              <i style="color:red"
                 @click="del(index,index+beforeZwChineseLen,'zwChineseList')"
                 class="el-icon-delete"></i>
            </div>
            <el-button type="primary"
                       @click="add('zw_chinese','zwChineseList')"
                       class="btn"
                       plain>
              新增语文作文
            </el-button>
          </div> -->

          <!-- 英语作文区域 -->
          <!-- <div class="box">
            <div class="area_title">英语作文区域<span>(英语试卷创建)</span></div>
            <div v-for="(item,index) in zwEnglishList"
                 :key="index+beforeZwEnglishLen"
                 @click="checkItem(index+beforeZwEnglishLen)"
                 :style="{color:index+beforeZwEnglishLen == activeIndex?'#409EFF':''}"
                 class="item">
              <span>
                <span v-if="item.isRequire"
                      style="color:#f00;margin-left:-20px">*</span>
                <span style="width:100px;display:inline-block">英语作文{{index+1}}</span>
                <el-input-number v-model="item.label"
                                 placeholder="请输入"
                                 :min="0"
                                 style="width:100px"
                                 controls-position="right" />
              </span>
              <span>x:{{(item.points.x).toFixed(2)}}</span>
              <span>y:{{(item.points.y).toFixed(2)}}</span>
              <span>w:{{(item.points.w).toFixed(2)}}</span>
              <span>h:{{(item.points.h).toFixed(2)}}</span>
              <i style="color:red"
                 @click="del(index,index+beforeZwEnglishLen,'zwEnglishList')"
                 class="el-icon-delete"></i>
            </div>
            <el-button type="primary"
                       @click="add('zw_english','zwEnglishList')"
                       class="btn"
                       plain>
              新增英语作文
            </el-button>
          </div> -->

          <!-- 批改区域 -->
          <div class="box"
               v-if='correct_type==1'>
            <div class="area_title">批改区域<span>(线下批改创建)</span></div>
            <div v-for="(item,index) in correctList"
                 :key="index+beforeZwEnglishLen"
                 @click="checkItem(index+beforeCorrectLen)"
                 :style="{color:index+beforeCorrectLen == activeIndex?'#409EFF':''}"
                 class="item">
              <span>
                <span v-if="item.isRequire"
                      style="color:#f00;margin-left:-20px">*</span>
                <span style="width:95px;display:inline-block">题目{{item.label}}</span>
                <el-input-number v-model="item.label"
                                 placeholder="请输入题号"
                                 :min="0"
                                 controls-position="right"
                                 style="width:105px" />
              </span>
              <span>x:{{(item.points.x).toFixed(2)}}</span>
              <span>y:{{(item.points.y).toFixed(2)}}</span>
              <span>w:{{(item.points.w).toFixed(2)}}</span>
              <span>h:{{(item.points.h).toFixed(2)}}</span>
              <i style="color:red"
                 @click="del(index,index+beforeCorrectLen,'correctList')"
                 class="el-icon-delete"></i>
            </div>
            <el-button type="primary"
                       @click="add('correct','correctList')"
                       class="btn"
                       plain>
              新增批改区域
            </el-button>
          </div>

        </div>

      </div>
      <div class="submit_btn">
        <el-button type="primary"
                   @click="submit">
          确定
        </el-button>
        <el-button @click="cancle">
          取消
        </el-button>
      </div>

    </div>

    <img :src="url"
         id="card_img1"
         alt="">
  </div>
</template>

<script>
// import html2canvas from 'html2canvas'
import { upload } from '@/api/upload.js'
import getMd5Form from '@/utils/getMd5Form.js'
import { getInstanceByDom } from 'echarts'

export default {
  data () {
    return {
      correct_type: 0,
      activeIndex: 0,
      active: false,
      top: 0,
      left: 0,
      // width: 0,
      // height: 0,
      lists: [],
      width1: 600,
      height1: 350,
      wrapWidth: 0,
      wrapHieght: 0,
      ratioX: 1,
      ratioY: 1,
      url: '',
      // 记录当前正反面,1正面2反面
      num: '',
      // 当前激活项
      activeIndex: 0,
      // typeOptions: [
      //   { label: "用户粘体的条码", value: 'uid' },
      //   { label: "用户填涂的准考证", value: 'rn' },
      //   { label: "用户手写准考证区域", value: 'rn_hand' },
      //   { label: "选择题区域", value: 'cq' },
      //   { label: "主观题区域", value: 'sq' },
      //   { label: "英语作文区域", value: 'zw_english' },
      //   { label: "语文作文区域", value: 'zw_chinese' },
      // ]

      // size: "",


      pointList: [],//定位点
      infoList: [],//学生信息
      cqList: [],//选择题
      gqList: [],//填空题
      sqList: [],//主观题
      zwEnglishList: [],//英语作文
      zwChineseList: [],//语文作文
      correctList: [],//批改区域
    }

  },
  mounted () {
    if (this.$route.query.correct_type) {
      this.correct_type = this.$route.query.correct_type
    }

  },
  props: ['size'],
  computed: {
    beforeInfoLen () {
      return this.pointList.length
    },
    beforeCqLen () {
      return this.infoList.length + this.pointList.length
    },
    beforeGqLen () {
      return this.infoList.length + this.pointList.length + this.cqList.length
    },
    beforeSqLen () {
      return this.infoList.length + this.pointList.length + this.cqList.length + this.gqList.length
    },
    beforeZwChineseLen () {
      return this.infoList.length + this.pointList.length + this.cqList.length + this.gqList.length + this.sqList.length
    },
    beforeZwEnglishLen () {
      return this.infoList.length + this.pointList.length + this.cqList.length + this.gqList.length + this.sqList.length + this.zwChineseList.length
    },
    beforeCorrectLen () {
      return this.infoList.length + this.pointList.length + this.cqList.length + this.gqList.length + this.sqList.length + this.zwChineseList.length + this.zwEnglishList.length
    },

  },
  methods: {
    cancle () {
      this.$emit('cancelSign')
    },
    // 获取定位点
    getPoint () {
      // if (this.size === 1) {
      //   this.pointList = this.lists.slice(0, 4)
      // } else {
      //   this.pointList = this.lists.slice(0, 8)
      // }
      console.log(this.lists)
      this.pointList = this.lists.filter(item => item.type == 'lp')
    },
    // 获取学生学习区域数据
    getInfo () {

      let info1 = this.lists.filter(item => item.type == "rn")
      let info2 = this.lists.filter(item => item.type == "rn_hand")
      let info3 = this.lists.filter(item => item.type == "uid")
      this.infoList.push(...info1, ...info2, ...info3)
      console.log(this.infoList, "info1")

    },
    getOtherList () {
      if (this.lists.length) {

        this.cqList = this.lists.filter(item => item.type == "cq")
        // this.sqList = this.lists.filter(item => item.type == "sq" && item.label.indexOf("gq") == -1 && item.type1 != 'gq')
        // this.gqList = this.lists.filter(item => (item.type == "sq" && item.label.indexOf("gq") != -1) || (item.type == "sq" && item.type1 == 'gq'))
        let arr = this.lists.filter(item => item.type == "sq")
        let SqPoints = []
        arr.map(item => {
          SqPoints.push(JSON.stringify(item.points));
        })
        let SqPointsSingle = [...new Set(SqPoints)]
        SqPointsSingle.forEach(item => {
          let temp = arr.filter(ele => JSON.stringify(ele.points) == item)
          console.log(temp, "temp")
          if (temp.length == 1) {
            this.sqList.push(...temp)
          } else {
            let t = temp[0]
            for (let i = 1; i < temp.length; i++) {
              t.label += ','
              t.label += temp[i].label
            }
            this.sqList.push(t)
          }
        })
        console.log(this.sqList, 'list')
        // this.sqList = this.lists.filter(item => item.type == "sq")
        // this.zwChineseList = this.lists.filter(item => item.type == "zw_chinese")
        // this.zwEnglishList = this.lists.filter(item => item.type == "zw_english")
        this.correctList = this.lists.filter(item => item.type == "correct")
      }
      console.log(this.cqList, 'cqList')
    },

    async initData (list, url, num) {
      this.url = url
      this.num = num
      await this.$nextTick(() => {
        let img = document.getElementById("card_img1")
        let that = this
        img.onload = function () {
          console.log(that.wrapWidth, img, img.naturalWidth, "11")
          that.wrapWidth = img.clientWidth * 0.3
          that.wrapHieght = img.clientHeight * 0.3
        }
      })

      this.lists = list
      await this.getPoint()
      await this.getInfo()
      await this.getOtherList()
      // if (!this.lists.length) {
      //   this.lists = [].concat(this.pointList, this.infoList, this.cqList, this.zwChineseList, this.zwEnglishList, this.correctList)
      // }
      this.lists = [].concat(this.pointList, this.infoList, this.cqList, this.sqList, this.correctList)
    },
    draggableFun (e) {
      // console.log('e', e)
      this.left = e.clientX - 30
      this.top = e.clientY - 30

      this.$set(this.lists[this.activeIndex].points, 'w', 0)
      this.$set(this.lists[this.activeIndex].points, 'h', 0)
      this.active = true

      this.$set(this.lists[this.activeIndex].points, 'x', e.clientX - 30)
      this.$set(this.lists[this.activeIndex].points, 'y', e.clientY - 30)
    },
    mousemove (e) {
      if (this.active) {
        let width = e.clientX - 30 - this.left
        let height = e.clientY - 30 - this.top
        this.$set(this.lists[this.activeIndex].points, 'w', width)
        this.$set(this.lists[this.activeIndex].points, 'h', height)
        this.$forceUpdate()
      }
    },
    up (e) {
      this.active = false
    },
    checkItem (index) {
      this.activeIndex = index

    },
    async submit () {
      // debugger
      for (let i = 0; i < this.pointList.length; i++) {
        if (this.size === 0) {
          if (i == 0 || i == 3 || i == 4 || i == 7) {
            if (!this.pointList[i].points.w || !this.pointList[i].points.h) {
              this.$message.warning('定位点1,4,5,8必填！')
              // break
              return false
            }
          }
        } else if (this.size == 1) {
          if (!this.pointList[i].points.w || !this.pointList[i].points.h) {
            this.$message.warning('定位点lp1,lp4,lp5,lp8必填！')
            // break
            return false
          }
        }

      }
      // 填空题
      // for (let i = 0; i < this.gqList.length; i++) {
      //   if (this.gqList[i].label == '') {
      //     if (this.gqList[i].points.w == 0 || this.gqList[i].points.h == 0) {
      //       this.gqList.splice(i, 1)
      //       i--
      //       continue
      //     }
      //   }
      // }
      for (let i = 0; i < this.cqList.length; i++) {
        if (this.cqList[i].points.w == 0 || this.cqList[i].points.h == 0) {
          this.$message.warning('选择题' + (i + 1) + '未标注区域')
          return false
        }
      }

      for (let i = 0; i < this.sqList.length; i++) {
        if (this.sqList[i].label == '') {
          if (this.sqList[i].points.w == 0 || this.sqList[i].points.h == 0) {
            this.sqList.splice(i, 1)
            i--
            continue
          }
        } else if (this.sqList[i].points.w == 0 || this.sqList[i].points.h == 0) {
          this.$message.warning('主观题' + this.sqList[i].label + '未标注区域')
          return false
        }
      }



      // for (let i = 0; i < this.zwEnglishList.length; i++) {
      //   if (this.zwEnglishList[i].label == '') {
      //     if (this.zwEnglishList[i].points.w == 0 || this.zwEnglishList[i].points.h == 0) {
      //       this.zwEnglishList.splice(i, 1)
      //       i--
      //       continue
      //     }
      //   } else if (this.zwEnglishList[i].points.w == 0 || this.zwEnglishList[i].points.h == 0) {
      //     this.$message.warning('英语作文' + (i + 1) + '未标注区域')
      //     return false
      //   }
      // }
      // for (let i = 0; i < this.zwChineseList.length; i++) {
      //   if (this.zwChineseList[i].label == '') {
      //     if (this.zwChineseList[i].points.w == 0 || this.zwChineseList[i].points.h == 0) {
      //       this.zwChineseList.splice(i, 1)
      //       i--
      //       continue
      //     }
      //   } else if (this.zwChineseList[i].points.w == 0 || this.zwChineseList[i].points.h == 0) {
      //     this.$message.warning('语文作文' + (i + 1) + '未标注区域')
      //     return false
      //   }
      // }

      for (let i = 0; i < this.correctList.length; i++) {
        if (this.correctList[i].label == '') {
          if (this.correctList[i].points.w == 0 || this.correctList[i].points.h == 0) {
            this.correctList.splice(i, 1)
            i--
            continue
          }
        } else if (this.correctList[i].points.w == 0 || this.correctList[i].points.h == 0) {
          this.$message.warning('批改' + this.correctList[i].label + '未标注区域')
          return false
        }
      }


      for (let i = 0; i < this.lists.length; i++) {
        console.log(this.lists[i])
        if (this.lists[i].label == '') {
          if (this.lists[i].points.w == 0 || this.lists[i].points.h == 0) {
            this.lists.splice(i, 1)
            i--
            continue
          }
          this.$message.warning('请检查题号是否都已填写！')
          // break
          return false
        }
      }
      if (this.num == 1) {
        let hasInfo = false
        for (let i = 0; i < this.infoList.length; i++) {
          console.log(i)
          if (this.infoList[i].points.w && this.infoList[i].points.h) {
            hasInfo = true
            break
          }
        }
        if (!hasInfo) {
          this.$message.warning('学生信息必填其中一项')
          return false
        }
      }

      // 用来提交的数据
      let submitLists = [].concat(this.pointList, this.infoList, this.cqList)
      this.sqList.forEach(item => {
        // 主观题多题则拆开
        let labelString = item.label.replace(/[\uff0c]/g, ",") //去掉中文逗号
        let labelArr = labelString.split(',')
        labelArr.forEach(i => {
          if (i != '') {
            let t = JSON.parse(JSON.stringify(item))
            t.label = i
            submitLists.push(t)
          }
        })
      })

      let lists = JSON.parse(JSON.stringify(submitLists)).map((item, index) => {
        item.points.y /= 0.3
        item.points.x /= 0.3
        item.points.w /= 0.3
        item.points.h /= 0.3

        return item
      })


      this.$emit('setData', this.num, '', JSON.stringify(lists))
    },
    toImgStyle (base64Str, fileName) {
      var arr = base64Str.split(','),
        mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
        bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
        len = bstr.length,
        u8arr = new Uint8Array(len); //
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
      };
      // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
      return new File([u8arr], fileName, {
        type: mime
      })
    },
    add (area, list) {
      let label
      let type
      // if (area == 'gq') {
      //   type = "sq"
      // } else {
      type = area
      // }
      // || area == 'gq'
      if (area == 'cq') {
        label = `${area}${this[list].length + 1}`
      }
      else {
        label = ''
      }
      let obj = {
        label: label,
        points: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        },
        color: this.rgbToHex(),
        type: type
      }
      // if (area == 'gq') {
      //   obj.type1 = 'gq'
      // }

      let beforeLen = 0
      if (list == "cqList") {
        beforeLen = this.pointList.length + this.infoList.length + this.cqList.length
        this.lists.splice(beforeLen, 0, obj)
      }
      // else if (list == "gqList") {
      //   beforeLen = this.pointList.length + this.infoList.length + this.cqList.length + this.gqList.length
      //   // this.lists.splice(beforeLen, 0, obj)
      // } 
      else if (list == "sqList") {
        beforeLen = this.pointList.length + this.infoList.length + this.cqList.length + this.gqList.length + this.sqList.length
        this.lists.splice(beforeLen, 0, obj)
      }
      // else if (list == "zwChineseList") {
      //   beforeLen = this.pointList.length + this.infoList.length + this.cqList.length + this.gqList.length + this.sqList.length + this.zwChineseList.length
      //   // this.lists.splice(beforeLen, 0, obj)
      // } else if (list == "zwEnglishList") {
      //   beforeLen = this.pointList.length + this.infoList.length + this.cqList.length + this.gqList.length + this.sqList.length + this.zwChineseList.length + this.zwEnglishList.length
      //   // this.lists.splice(beforeLen, 0, obj)
      // }
      else if (list == "correctList") {
        // beforeLen = this.pointList.length + this.infoList.length + this.cqList.length + this.gqList.length + this.sqList.length + this.zwChineseList.length + this.zwEnglishList.length + this.correctList.length
        beforeLen = this.pointList.length + this.infoList.length + this.cqList.length + this.gqList.length + this.sqList.length + this.correctList.length
        this.lists.splice(beforeLen, 0, obj)
      }

      //  this.activeIndex = this.lists.length - 1
      this.activeIndex = beforeLen
      this[list].push(obj)
      // console.log("listss", this.lists)
      // console.log("listss", this[list])
    },
    del (index, index1, list) {
      this.lists.splice(index1, 1)
      this[list].splice(index, 1)
    },
    rgbToHex () {
      return `#${Math.random().toString(16).substr(2, 6)}`;
    },
    // getContrastYIQ (hexcolor) {//获取文字颜色
    //   var r = parseInt(hexcolor.substr(0, 2), 16);
    //   var g = parseInt(hexcolor.substr(2, 2), 16);
    //   var b = parseInt(hexcolor.substr(4, 2), 16);
    //   var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    //   return (yiq >= 128) ? 'black' : 'white';
    // }

  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  padding: 30px;
  #do_card_wrap {
    // background: grey;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 2px solid #d9d9d9;
    .line {
      position: absolute;
      background: red;
      opacity: 0.3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 16px;
      z-index: 1999;
    }
  }
  .right_wrap {
    // flex: 1;
    min-width: 500px;

    .list {
      margin-left: 20px;
      // width: 50vw;
      height: calc(100vh - 120px);
      overflow: auto;
      .item {
        margin: 30px 0;
        cursor: pointer;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}
#card_img1 {
  // 把图片放到页面视图以外的位置，js获取图片宽高
  position: fixed;
  right: 1000000px;
}
.add {
  width: 24px;
  height: 24px;
  margin-bottom: 20px;
  cursor: pointer;
}
::v-deep .el-button {
  padding: 10px 20px;
  margin: 10px 20px 10px 0;
  width: auto;
  //  height: 36px;
}
.box {
  border-radius: 5px;
  box-shadow: 0px 4px 10px #ccc;
  padding: 20px 20px 10px 40px;
  min-height: 50px;
  margin: 10px;
  .area_title {
    font-weight: 700;
    // font-size: 15px;
    margin-bottom: -15px;
    span {
      color: #999;
      padding-left: 10px;
    }
  }
  .btn {
    margin-top: 25px;
  }
}
.submit_btn {
  flex: 1;
  .el-button {
    width: 45%;
    margin: 20px 2% 0;
  }
  span {
    width: 100%;
  }
}
.left_img {
  min-width: 400px;
  min-height: 600px;
  background: #eee;
}
.tip {
  margin-top: 20px;
  line-height: 26px;
  padding: 5px 10px;
  border-radius: 2px;
  background: #efefef;
  font-size: 13px;
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgba(202, 202, 210, 0.8);
  border-radius: 50px;
  cursor: pointer;
}
/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20px;
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>